<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area ptb--60 bg_image bg_image--27"
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center blog-single-page-title pt--100">
              <h1 class="heading-title theme-gradient">
                Suivi d'une demande d'assistance
              </h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Start Blog Details Area  -->
    <div class="rn-blog-details pt--60 pb--60 bg_color--1">
      <v-container v-if="isActive == undefined">
        <div class="page-loader page-loader-logo align-middle p-5 m-5 col-12">
          <div class="align-content-center align-center text-center">
            <div class="text-center" style="min-height: 300px">
              <v-col>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-col>
              <v-col> Vérification de la notification</v-col>
            </div>
          </div>
        </div>
      </v-container>

      <v-container>
        <v-row>
          <v-col v-if="isActive == false" cols="12">
            <div class="align-content-center align-center text-center">
              <div class="text-center" style="min-height: 300px">
                <v-col>
                  <h2>Le suivi de l'alerte n'est plus disponible.</h2>
                  <br />
                  Le lien que vous avez ouvert est trop ancien ou vous avez deja
                  fournit votre status de suivi.
                </v-col>
              </div>
            </div>
          </v-col>
          <v-col v-if="isActive == true" cols="12">
            <div class="align-content-center align-center text-center">
              <div
                v-if="canSendStatus == true"
                class="text-center"
                style="min-height: 300px"
              >
                <v-col>
                  <h2>
                    Pouvez-vous prendre en charge la demande d'assistance de
                    {{ given_name }} ?
                  </h2>
                </v-col>
                <v-col class="py-5">
                  <v-btn
                    width="150px"
                    :disabled="isWaiting"
                    :loading="isWaiting"
                    class="mx-4 my-4"
                    color="success"
                    v-on:click="sendStatus(true)"
                  >
                    OUI
                    <v-icon dark right>
                      mdi-checkbox-marked-circle
                    </v-icon>
                    <template v-slot:isWaiting>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                  <v-btn
                    width="150px"
                    :disabled="isWaiting"
                    :loading="isWaiting"
                    class="mx-4 my-4"
                    color="primary"
                    v-on:click="sendStatus(false)"
                  >
                    NON
                    <v-icon dark right>
                      mdi-cancel
                    </v-icon>
                    <template v-slot:isWaiting>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-col>
              </div>
              <div class="text-center" style="min-height: 300px">
                <v-col class="py-1">
                  <h2>
                    Les amis de confiance suivants ont été alertés:
                  </h2>
                </v-col>
                <v-col class="py-5">
                  <v-simple-table height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            Calories
                          </th>
                          <th class="text-left" style="min-width: 200px">
                            Mise à jours
                          </th>
                          <th class="pr-0 text-right" style="min-width: 200px">
                            Suivi de la demande
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in listUsers" :key="item.name">
                          <td class="text-left">
                            {{ item.name }}
                          </td>
                          <td class="text-left">
                            {{ item.email }}
                          </td>
                          <td class="text-left">
                            {{ item.updatedAt }}
                          </td>
                          <td class="text-right">
                            <span
                              v-if="
                                item.status == null && item.current == false
                              "
                            >
                              <v-icon color="red darken-2">mdi-alert</v-icon>en
                              attente</span
                            >
                            <span
                              v-else-if="
                                item.status == null && item.current == true
                              "
                            >
                              <v-icon color="red darken-2">mdi-alert</v-icon>En
                              attente
                            </span>
                            <span v-else-if="item.status == true">
                              <v-icon color="green darken-2" v-if="item.status">
                                mdi-checkbox-marked-circle </v-icon
                              >disponible</span
                            >
                            <span v-else-if="item.status == false">
                              <v-icon color="red darken-2" v-if="item.status">
                                mdi-checkbox-marked-circle </v-icon
                              >non disponible</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Details Area  -->
  </div>
</template>

<script>
import API from "@aws-amplify/api";
import Swal from "sweetalert2";
import { load } from "recaptcha-v3";
//import {getCONTACT, getNOTIFICATION} from "../graphql/queries";

export default {
  components: {},
  methods: {
    async sendStatus(status) {
      if (this.$route.query.id === undefined) {
        Swal.fire({
          title: "",
          text: "Une erreur est survenue, merci de réessayer ultérieurement",
          icon: "error",
          heightAuto: false
        });
        return;
      }
      this.isWaiting = true;
      this.token = await this.captcha.execute("track");
      if (this.token === undefined) {
        Swal.fire({
          title: "",
          text: "Une erreur est survenue, merci de réessayer ultérieurement",
          icon: "error",
          heightAuto: false
        });
        this.isWaiting = false;
        return;
      }
      const result = await API.post(
        "apicc7ddc29friend",
        "/follow-alert/" + this.$route.query.id,
        {
          body: {
            status,
            "g-recaptcha-response": this.token
          }
        }
      );
      if (result.status === 1) {
        this.refreshListUser();
        Swal.fire({
          title: "",
          text: "Nous notifions " + this.given_name + ".",
          icon: "info",
          heightAuto: false
        });
      } else if (result.status === -5) {
        Swal.fire({
          title: "",
          text: "Vous ne pouvez pas changer le status du suivi.",
          icon: "warning",
          heightAuto: false
        });
      } else {
        Swal.fire({
          title: "",
          text: "Une erreur est survenue, merci de réessayer ultérieurement",
          icon: "error",
          heightAuto: false
        });
      }
      this.isWaiting = false;
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      this.captcha = await load("6LdnVjsdAAAAAOOL4CEwlEDp8O2tbucM0-hrg2a2");
    },
    async getAlertStatus() {
      this.token = await this.captcha.execute("track");
      if (this.token === undefined) {
        throw new Error("Error generating Repatcha");
      }
      return API.get(
        "apicc7ddc29friend",
        "/follow-alert/" +
          this.$route.query.id +
          "?g-recaptcha-response=" +
          this.token
      );
    },
    refreshListUser() {
      this.countInterval++;
      if (this.countInterval > 2) {
        clearInterval(this.interval);
      }
      this.getAlertStatus()
        .then(result => {
          if (result.status === 1) {
            this.canSendStatus = true;
          } else {
            this.canSendStatus = false;
          }
          if (result.notification) {
            this.listUsers = result.notification.contacts;
          }
        })
        .catch(err => {
          Swal.fire({
            title: "Please, retry later",
            text: err.message,
            icon: "error",
            heightAuto: false
          });
        });
    }
  },

  async mounted() {
    await this.recaptcha();
    this.isWaiting = true;
    this.getAlertStatus()
      .then(result => {
        if (result.status >= 0) {
          this.isActive = true;
        } else {
          this.isActive = false;
        }
        if (result.status === 1) {
          this.canSendStatus = true;
        }
        if (result.notification) {
          this.given_name = result.given_name;
          this.listUsers = result.notification.contacts;
          this.interval = setInterval(() => this.refreshListUser(), 60000);
        }
        this.isWaiting = false;
      })
      .catch(err => {
        Swal.fire({
          title: "Please, retry later",
          text: err.message,
          icon: "error",
          heightAuto: false
        });
      });
  },
  data() {
    return {
      countInterval: 0,
      interval: undefined,
      showDialog: false,
      given_name: "",
      isWaiting: false,
      token: undefined,
      captcha: undefined,
      canSendStatus: false,
      isActive: undefined,
      listUsers: []
    };
  }
};
</script>
